import React, {useRef, useState} from "react";
import {graphql} from "gatsby";
import {Helmet} from "react-helmet";
import defi from "../images/home/defi.svg";
import gamefi from "../images/home/gamefi.svg";
import web3 from "../images/home/web3.svg";
import nft from "../images/home/nft.svg";
import coins from "../images/home/coins.svg";
import tronbullWave from "../images/tronpedia/tronbullwave.png";
import Search from "../components/tronpedia/search";
import Topics from "../components/tronpedia/topics";
import EmailCapture from "../components/EmailCapture";
import {AiFillCaretLeft, AiFillCaretRight, FaCircle,} from "react-icons/all";
import carouselNft from "../images/tronpedia/carousel/nft.png";
import carouselDefi from "../images/tronpedia/carousel/defi.png";
import carouselBlockchain from "../images/tronpedia/carousel/blockchain.png";
import carouselCrypto from "../images/tronpedia/carousel/crypto.png";
import carouselGamefi from "../images/tronpedia/carousel/gamefi.png";
import carouselStablecoin from "../images/tronpedia/carousel/stablecoin.png";
import carouselWeb3 from "../images/tronpedia/carousel/web3.png";
import Featured from "../components/tronpedia/featured";

const Tronpedia = ({data, location}) => {
    const allTopics = [
        {
            img: carouselDefi,
            title: "DEFI",
            description: "So… I can bank without a bank? Check out our articles to learn about how trust-free finance works and some of the biggest protocols in the space now.",
            articles: [],
        },
        {
            img: carouselGamefi,
            title: "GAMEFI",
            description: "Play-to-earn? Yeah… really. This section covers how GameFi makes this a possibility, and what it is likely to become the future of video games.",
            articles: [],
        },
        {
            img: carouselNft,
            title: "NFT",
            description: "Non fungi-what!? Here, we break down NFTs and discuss what they are, their potential to revolutionize industries, and some of the most-hyped projects.",
            articles: [],
        },
        {
            img: carouselWeb3,
            title: "WEB3",
            description: "Welcome to the future. These articles cover how we have and potentially will be acting with the internet. They also talk about some of the biggest names in the game trying to move it forward.",
            articles: [],
        },
        {
            img: carouselStablecoin,
            title: "STABLECOIN",
            description: "Stability… in crypto?? This section covers stablecoins, their importance in the digital landscape, and the biggest providers of them.",
            articles: [],
        },
        {
            img: carouselCrypto,
            title: "CRYPTO",
            description: "Here, we break down what cryptocurrencies are, how they’ve worked at such a massive scale, and the biggest names in the space.",
            articles: [],
        },
        {
            img: carouselBlockchain,
            title: "BLOCKCHAIN",
            description: "This section covers how distributed ledger technology works, why many believe it will be the future of the internet, and how organizations are using it today.",
            articles: [],
        },
    ]

    for (let i = 0; i < data.allWpTronpediaArticle.edges.length; i++) {
        let article = data.allWpTronpediaArticle.edges[i].node;
        let category = article.tronpedia_article_info.category;
        for (let j = 0; j < allTopics.length; j++) {
            if (allTopics[j].title.toLowerCase() === category.toLowerCase()) {
                allTopics[j].articles.push(article);
                break;
            }
        }
    }

    const [currentTopic, setCurrentTopic] = useState(allTopics.slice(0, 3));
    const currentIndex = useRef(3);

    const nextTopic = () => {
        if (currentIndex.current === allTopics.length - 1) {
            currentIndex.current = 0;
        } else {
            currentIndex.current = currentIndex.current + 1;
        }
        updateTopicsAfterIndexUpdate();
    }

    const previousTopic = () => {
        if (currentIndex.current === 0) {
            currentIndex.current = allTopics.length - 1;
        } else {
            currentIndex.current = currentIndex.current - 1;
        }
        updateTopicsAfterIndexUpdate()
    }

    const setIndex = (index) => {
        currentIndex.current = index;
        updateTopicsAfterIndexUpdate();
    }


    const updateTopicsAfterIndexUpdate = () => {
        let newTopics = [];
        if (currentIndex.current === 0) {
            newTopics.push(allTopics[allTopics.length - 1]);
            newTopics.push(allTopics[0]);
            newTopics.push(allTopics[1])
        } else if (currentIndex.current === allTopics.length - 1) {
            newTopics.push(allTopics[currentIndex.current - 1]);
            newTopics.push(allTopics[currentIndex.current]);
            newTopics.push(allTopics[0]);
        } else {
            newTopics.push(allTopics[currentIndex.current - 1]);
            newTopics.push(allTopics[currentIndex.current]);
            newTopics.push(allTopics[currentIndex.current + 1]);
        }
        setCurrentTopic(newTopics);
    }


    return (
        <div className="tronpedia">
            <Helmet>
                <title>TRONpedia | TRONDAO</title>
                <meta name="description"
                      content="Want to learn everything there is to know about the new age of technology? Look no further than TRONPedia, the ultimate source for all your needs!"/>
                <meta property="og:description"
                      content="Want to learn everything there is to know about the new age of technology? Look no further than TRONPedia, the ultimate source for all your needs!"/>
                <meta property="og:title" content="TRONpedia | TRONDAO"/>
            </Helmet>
            <div className="row no-gutters mt-5 p-lg-5 p-2">
                <div className="col-md-6 col-12 align-items-center">
                    <div className="tronpedia-header"><h1>TRONPEDIA</h1></div>
                    <div className="tronpedia-content mt-3">Welcome to TRONPedia, your go to source for learning about
                        all the different types of projects we support. Whether you’re a newbie or a dedicated TRONic,
                        we believe you can grasp something new about the exciting steps we’re taking.
                    </div>
                    <div className="w-100 my-2 d-flex flex-wrap justify-content-around">
                        <img src={defi} className="tronpedia-icons" alt="tron dao defi"/>
                        <img src={gamefi} className="tronpedia-icons" alt="tron dao web3"/>
                        <img src={web3} className="tronpedia-icons" alt="tron dao web3"/>
                        <img src={nft} className="tronpedia-icons" alt="tron dao nft"/>
                        <img src={coins} className="tronpedia-icons" alt="tron dao coins"/>
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 col-12 align-items-center">
                    <div className="tronpedia-tronbull text-center">
                        <img src={tronbullWave} className="tronpedia-tronbull-icon img-fluid" alt="tron bull wave"/>
                    </div>
                </div>
            </div>
            <div className="row no-gutters mt-5 p-lg-5 p-2">
                <h3 className="">TRONPEDIA</h3>
                <div className="col-12">
                    <Search location={location}/>
                </div>
            </div>

            <div className="row no-gutters mt-5 p-lg-5 p-2">
                <h3 className="">TOPICS</h3>
                <div className="col-12">
                    <Topics/>
                </div>
            </div>

            <div className="row no-gutters mt-5 p-lg-5 p-0">
                <div className="w-100 tronpedia-carousel">
                    <div className="tronpedia-carousel-button d-inline-flex">
                        <AiFillCaretLeft className="ml-0 ml-lg-5 cursor-pointer tronpedia-carousel-button-btn"
                                         color="#7C0008" size="65px"
                                         onClick={previousTopic}
                                         id="tronpedia-carousel-left"/>

                        <AiFillCaretRight className="ml-auto mr-0 mr-lg-5  cursor-pointer tronpedia-carousel-button-btn"
                                          color="#7C0008"
                                          id="tronpedia-carousel-right"
                                          onClick={nextTopic}
                                          size="65px"/>
                    </div>
                </div>
                <div className="tronpedia-carousel-card-wrapper">
                    <div
                        className="tronpedia-carousel-card-background justify-content-center d-flex align-items-center"
                        onClick={previousTopic}
                        onKeyDown={function (e) {
                            if (e.key === "Enter") {
                                previousTopic()
                            }
                        }}
                        role="button"
                        aria-label={"View Previous Topic"}
                        tabIndex="0">
                        <img src={currentTopic[0].img} className={"img-fluid cursor-pointer"}
                             alt={"carousel-" + currentTopic[0].title}/>
                    </div>

                    <div
                        className="tronpedia-carousel-card-background justify-content-center d-flex align-items-center"
                        onClick={nextTopic}
                        onKeyDown={function (e) {
                            if (e.key === "Enter") {
                                nextTopic()
                            }
                        }}
                        role="button"
                        aria-label={"View Next Topic"}
                        tabIndex="0">
                        <img src={currentTopic[2].img} className={"img-fluid cursor-pointer"}
                             alt={"carousel-" + currentTopic[2].title}/>
                    </div>
                </div>
                <div className="w-100 d-flex justify-content-center tronpedia-carousel-card-wrapper">
                    <div className="tronpedia-carousel-card">
                        <div className="d-flex align-items-center flex-wrap justify-content-md-start justify-content-center">
                            <img src={currentTopic[1].img} alt={currentTopic[1].title}/>
                            <h4 className="m-3">{currentTopic[1].title}</h4>
                        </div>
                        <div className="d-flex align-items-center">
                            <p>{currentTopic[1].description}</p>
                        </div>
                        <div className="d-flex align-items-center">
                            <ul>
                                {currentTopic[1].articles.length > 0 && currentTopic[1].articles.slice(0, 4).map(article => {
                                    return (
                                        <li className="tronpedia-carousel-card-list"><a
                                            href={"/tronpedia/" + article.slug}
                                            id={"tronpedia-carousel-card-" + article.slug}>{article.title}</a></li>
                                    )
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="d-flex justify-content-center my-5 w-100">
                {allTopics.length > 0 && allTopics.map((topic, index) => {
                    let isActive = currentIndex.current === index ? ' tronpedia-carousel-card-active ' : '';
                    return (
                        <FaCircle className={'cursor-pointer mx-3' + isActive}
                                  id={"all-topics-bottom-circles-" + index}
                                  key={"all-topics-bottom-circles-" + index}
                                  color={"#BFBEBE"}
                                  onClick={function () {
                                      setIndex(index)
                                  }}
                        />
                    )

                })
                }

            </div>

            <Featured/>

            <EmailCapture/>


        </div>
    );
};


export default Tronpedia;

export const pageQuery = graphql`
query {
   allWpTronpediaArticle {  
      edges {
         node {
            slug
        title
        tronpedia_article_info {
          category
          featured
          seoDescription
          seoTitle
          shortDescription
          image {
                  sourceUrl
               }
            }
         }
      }
   }
}

`
